import React from "react"
import PropTypes from "prop-types"

class Profile extends React.Component {
    render() {
        return (
            <div className="media">
                <div className="media-left">
                    <p className="image is-48x48">
                        <a href={this.props.email_article_path}><img src={this.props.photo_url}/></a>
                    </p>
                </div>
                <div className="media-content">
                    <div className="content">
                        <p>
                            <a href={this.props.email_article_path}><strong>{this.props.user_name}</strong></a>
                            {' '}
                            <small>{this.props.date} ago</small>
                            {' '}
                            {this.props.user_signed_in &&
                            <span>
                                <span className="tag is-primary"><a href={this.props.edit_article_path}>Edit</a> </span>
                                {' '}
                                <span className="tag is-light is-danger"><a data-confirm="Are you sure?" rel="nofollow"
                                                                            data-method="delete"
                                                                            href={this.props.article_path}>Destroy</a></span>
                            </span>
                            }
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

Profile.propTypes = {
    user_name: PropTypes.string,
    email_article_path: PropTypes.string,
    photo_url: PropTypes.string,
    user_signed_in: PropTypes.bool,
    date: PropTypes.string,
    edit_article_path: PropTypes.string,
    article_path: PropTypes.string
};

export default Profile
